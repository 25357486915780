import { gsap } from 'gsap';

<template>
  <div id="start">
    <div class="start__branding">
      <img ref="logo" src="@/assets/home_photo.jpg" alt="Segel" />
    </div>

    <div class="start__welcome">
      <h1 ref="welcomeTitle">¡Te damos la bienvenida 👋 a nuestro Segel Quiz!</h1>

      <p ref="welcomeText1">🤔 Piensa bien antes de seleccionar tu respuesta, una vez elijas, avanzarás automáticamente a
        la siguiente pregunta y no podrás volver ⬅️ atrás. </p>

      <p ref="welcomeText2">¡Disfruta del Quiz! 💪🏼</p>

      <footer class="footer">
        <button ref="startButton" @click="$emit('startQuiz')">Comenzar</button>
        <button ref="formButton" class="buttonAlt" @click="$emit('showFormEvent')">Rellenar formulario</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'StartScreen',
  mounted() {
    const animationSettings = {
      opacity: 0,
      y: 50,
      duration: 1,
      ease: 'power3.out'
    };

    gsap.from(this.$refs.welcomeTitle, animationSettings);
    gsap.from(this.$refs.welcomeText1, { ...animationSettings, delay: 0.5 });
    gsap.from(this.$refs.welcomeText2, { ...animationSettings, delay: 0.65 });
    gsap.from(this.$refs.startButton, { ...animationSettings, delay: 0.75 });
    gsap.from(this.$refs.formButton, { ...animationSettings, delay: 1 });
  },
  beforeUnmount() {
    gsap.to(this.$el, { opacity: 0, duration: 1, ease: 'power3.in' });
  },

};
</script>

<style scoped>
#start {
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: .75fr 1.25fr;
}

.start__branding {
  height: 100%;
  width: 100%;
}

.start__branding img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.start__welcome {
  padding: 2rem;
}

.start__welcome p {
  width: 50ch;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.buttonAlt {
  background-color: transparent;
  border: 1px solid #d31d1d;
  color: #d31d1d;
}

h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
  #start {
    grid-template-columns: 100%;
    grid-template-rows: .50fr 1.50fr;
  }

  .start__branding img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .start__welcome {
    width: 100%;
    padding: 1rem;
  }

  .start__welcome p {
    width: 100%;
  }


  h1 {
    font-size: 1.5rem;
  }
}
</style>
