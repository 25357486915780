<template>
  <div>
    <loading v-model:active="isLoading" is-full-page="true"></loading>
    <div class="quiz__form">
      <div class="quiz__inputs">
        <h2>Rellena el formulario y recibe tu premio</h2>
        <form @submit.prevent="submitForm">
          <input id="id" v-model="form.document" @focus="activeInput = 'document'" placeholder="Cédula de Identidad"
                 @blur="verifyDocument" maxlength="7" required />
          <input id="name" v-model="form.name" @focus="activeInput = 'name'" placeholder="Nombre" required />
          <input id="email" v-model="form.email" @focus="activeInput = 'email'" type="email" placeholder="Email"
                 required />
          <input id="company" v-model="form.company" @focus="activeInput = 'company'"
                 placeholder="Empresa para la que trabaja" required />
          <input id="position" v-model="form.position" @focus="activeInput = 'position'" placeholder="Cargo" required />
          <input id="agent" v-model="form.agent" @focus="activeInput = 'agent'" placeholder="Vendedor" required />
          <div class="checkbox-group">
            <h3>¿Sobre cuál de los productos te gustaría recibir información?:</h3>
            <div class="checkbox_cloud">
              <label class="checkbox-item" v-for="product in products" :key="product.id">
                <input type="checkbox" :value="product.name" v-model="form.products">
                <span>{{ product.name }}</span>
              </label>
            </div>
          </div>
          <button id="submit" type="submit">Enviar</button>
        </form>
      </div>
      <QuizKeyboard @pressKey="pressKey" @focusNextInput="focusNextInput" @deleteLastChar="deleteLastChar"
                    @submitForm="submitForm" />
      <div class="misc" v-if="showMisc">
        <div class="qr">
          <img id="qrimg" src="@/assets/qr.svg" alt="QR Form" />
          <p>Escaneá el código QR si deseas completar el formulario desde tu celular</p>
        </div>
        <div class="legal">
          <p>Al enviar este formulario, aceptas que SEGEL recogerá y procesará la información personal que proporcionas,
            incluyendo tu nombre y dirección de correo electrónico, con el fin de responder a tu solicitud y enviar
            información relevante sobre nuestros servicios.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import QRCode from 'qrcode';
import rewards from '@/assets/rewards.json';
import products from '@/assets/products.json';
import QuizKeyboard from './QuizKeyboard.vue';

export default {
  name: 'UserForm',
  components: {
    Loading,
    QuizKeyboard
  },
  props: ['rewardForm'],
  data() {
    return {
      form: {
        name: '',
        document: '',
        email: '',
        company: '',
        position: '',
        products: [],
        reward: null
      },
      isLoading: false,
      activeInput: null,
      rewards: [],
      products: []
    };
  },
  computed: {
    showMisc() {
      const urlParams = new URLSearchParams(window.location.search);
      return !urlParams.has('reward');
    }
  },
  mounted() {
    this.form.reward = this.rewardForm;
    const documentInput = document.getElementById('id');
    if (documentInput) {
      documentInput.focus();
    }
    this.loadRewards();
    this.loadProducts();
    this.getRewardFromUrl();
    this.generateQR();
  },
  methods: {
    loadRewards() {
      this.rewards = rewards;
    },
    loadProducts() {
      this.products = products;
    },
    getRewardFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const rewardName = urlParams.get('reward');
      if (rewardName) {
        const rewardObject = this.rewards.find(reward => reward.name === rewardName);
        if (rewardObject) {
          this.form.reward = rewardObject;
          this.showForm = true;
        }
      }
    },
    async generateQR() {
      try {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        let url = `${baseUrl}/?open=form`;
        if (this.form.reward && this.form.reward.name) {
          url = `${baseUrl}/?reward=${encodeURIComponent(this.form.reward.name)}`;
        }
        const qrImage = await QRCode.toDataURL(url);
        const img = document.getElementById('qrimg');
        img.src = qrImage;
      } catch (err) {
        console.error(err);
      }
    },
    deleteLastChar() {
      if (this.activeInput && this.form[this.activeInput].length > 0) {
        this.form[this.activeInput] = this.form[this.activeInput].slice(0, -1);
      }
    },
    focusNextInput() {
      const inputsOrder = ['document', 'name', 'email', 'company', 'position', 'agent'];
      const currentIndex = inputsOrder.indexOf(this.activeInput);
      if (this.activeInput === 'document') {
        this.verifyDocument();
      }
      if (currentIndex >= 0 && currentIndex < inputsOrder.length - 1) {
        this.activeInput = inputsOrder[currentIndex + 1];
        this.$nextTick(() => {
          const nextInput = document.getElementById(inputsOrder[currentIndex + 1]);
          if (nextInput) {
            nextInput.focus();
          }
        });
      }
    },
    pressKey(keyValue) {
      if (this.activeInput) {
        this.form[this.activeInput] += keyValue;
      }
    },
    async verifyDocument() {
      if (!this.form.document || this.form.document.length === 0) {
        return;
      }
      let data = this.form;
      this.isLoading = true;
      try {
        const response = await axios.post('https://segel-quiz-admin.jmf.digital/api/get-data-by-document', { data });
        if (!response.data.error) {
          this.isLoading = false;
          this.form.name = response.data.data.customer.name;
          const documentInput = document.getElementById('email');
          if (documentInput) {
            documentInput.focus();
          }
        } else {
          this.isLoading = false;
          console.error(response.data.message);
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    submitForm() {
      if (!this.form.email.includes('@') || this.form.document.length > 7) {
        alert('Verifica los datos ingresados');
        return;
      }
      if (this.form.products.length === 0) {
        alert('Debe seleccionar al menos un producto.');
        return;
      }
      let data = this.form;
      console.error(data);
      axios.post('https://segel-quiz-admin.jmf.digital/api/save-lead', { data })
          .then(response => {
            console.error(response);
            this.$emit('formSubmitted', true);
          })
          .catch(error => {
            console.error(error);
            this.$emit('formSubmitted', true);
          });
      this.$emit('formSubmitted', true);
    }
  }
};
</script>

<style scoped>
.quiz__form {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
}

.quiz__form h2 {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -1px;
  margin-bottom: 2rem;
}

.quiz__inputs {
  height: 100%;
  padding: 2rem 2rem 1rem 2rem;
}

input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 1rem;
  padding: .85rem;
  box-sizing: border-box;
  font-size: 1rem;
  border-radius: .5rem;
}

button {
  width: 100%;
}

.misc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  gap: 2rem;
  color: rgb(126, 126, 126);
  align-items: start;
}

.misc p {
  font-size: .85rem;
}

.misc a {
  color: rgb(41, 41, 80);
}

.qr {
  display: flex;
  gap: 1rem;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.checkbox-group h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.checkbox_cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.checkbox-item {
  display: flex;
  position: relative;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #f2f2f2;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.2s ease, border 0.2s ease;
  border: 2px solid transparent;
  font-weight: 700;
  font-size: .85rem;
}

.checkbox-item input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox-item input:checked+span {
  color: #d31d1d;
}

.checkbox-item input:checked~.checkbox-item {
  background-color: #007BFF;
  border: 2px solid #007BFF;
}

@media (max-width: 575.98px) {
  .quiz__inputs {
    padding: 1rem;
  }

  .quiz__form h2 {
    font-size: 1.5rem;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }

  .quiz__keyboard {
    visibility: hidden;
    display: none;
  }

  input {
    margin: 0 0 .75rem;
    padding: .75rem;
    font-size: 1rem;
  }

  .legal {
    padding: 1rem;
  }

  .misc {
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 0;
  }

  .legal {
    text-align: center;
  }

  .qr {
    //visibility: hidden;
    //display: none;
  }
}
</style>
