<template>
  <div class="quiz__winner">

    <div class="winner__prize">
      <h2 v-if="correctAnswers === 3">¡Lo lograste! 🥳 ¡Terminaste el Segel Quiz con éxito!</h2>
      <h2 v-else-if="correctAnswers === 1">¡Terminaste el Segel Quiz con {{correctAnswers}} respuesta correcta!</h2>
      <h2 v-else>¡Terminaste el Segel Quiz con {{correctAnswers}} respuestas correctas!</h2>
      <h3>Te ganaste {{reward.article}} "{{reward.name}}" 🎉</h3>
      <img :src="getImagePath(reward.image)" alt="Prize" />
    </div>

    <div class="winner__form">
      <p>Pare recibir el premio completa el siguiente formulario 😊</p>
      <button @click="$emit('showForm', JSON.parse(JSON.stringify(reward)))">Rellenar formulario</button>
    </div>
  </div>
</template>

<script>
import rewards from '@/assets/rewards.json';

export default {
  name: 'EndScreen',
  props: ['correctAnswers'],
  data() {
    return {
      reward: {},
      rewards: []
    };
  },
  created() {
    this.loadRewards();
  },
  methods: {
    loadRewards() {
      this.rewards = rewards;
      this.selectReward();
    },
    selectReward() {
      let possibleRewards = this.rewards.filter((reward) => {
        return this.correctAnswers >= reward.min && this.correctAnswers <= reward.max;
      });

      if (possibleRewards.length > 0) {
        let randomIndex = Math.floor(Math.random() * possibleRewards.length);
        this.reward = possibleRewards[randomIndex];
      }
    },
    getImagePath(image) {
      return require(`@/assets/${image}`);
    }
  }
};
</script>

<style scoped>
/* Tu estilo existente */
.quiz__winner {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}

.winner__prize {
  height: 100%;
  padding: 2rem;
}

.winner__prize h2 {
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -3px;
}

.winner__prize h3 {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.2;
  margin-top: 1rem;
}

.winner__prize img {
  margin-top: 2rem;
  height: 70%;
  width: 100%;
  object-fit: contain;
}

.winner__form {
  background-color: #E4172C;
  padding: 2rem;
}

.winner__form p {
  font-size: 2.1rem;
  color: white;
  font-weight: 600;
  line-height: 1.2;
  padding-right: 30%;
}

.winner__form button {
  background-color: white;
  color: #E4172C;
  margin-top: 2rem;
  widows: 100%;
  display: block;
}

@media (max-width: 575.98px) {
  .winner__prize {
    padding: 1rem;
  }

  .winner__prize h2 {
    font-size: 1.5rem;
    letter-spacing: .5px;
  }

  .winner__prize h3 {
    font-size: 1rem;
    margin-top: .5rem;
  }

  .winner__prize img {
    margin-top: 1rem;
  }

  .winner__form {
    padding: 1rem;
  }

  .winner__form p {
    font-size: 1.3rem;
    padding-right: 0;
  }

  .winner__form button {
    margin-top: 1rem;
  }
}
</style>
