export default {
  colors: {
    primary: '#0099ff',
    secondary: '#1c5480',
    accent: '#ff6600',
    background: '#f4f4f4',
    text: '#333333'
  },
  typography: {
    baseFontSize: '16px',
    fontFamily: '"Roboto", sans-serif',
    headerFontFamily: '"Roboto Condensed", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '32px'
  }
};
