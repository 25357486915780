<template>
  <main id="main">
    <div id="container">
      <transition name="fade">
        <div class="wrapper" :key="componentKey">
          <StartScreen v-if="!quizStarted && !showForm" @startQuiz="startQuiz" @showFormEvent="showFormEvent" />
          <Quiz v-if="quizStarted && !quizFinished" @endQuiz="endQuiz" />
          <EndScreen v-if="quizFinished && !showForm" @showForm="showFormEvent" :correctAnswers="correctAnswers"
            :totalQuestions="totalQuestions" />
          <UserForm v-if="showForm" @formSubmitted="resetQuiz" :rewardForm="reward"/>
        </div>
      </transition>
    </div>
    <img ref="logo" class="powered" src="@/assets/powered_by_possible.svg" alt="Powered by POSsible" />
  </main>
</template>

<script>
import StartScreen from './components/StartScreen.vue'
import Quiz from './components/Quiz.vue'
import EndScreen from './components/EndScreen.vue'
import UserForm from './components/Form.vue'

export default {
  components: {
    StartScreen,
    Quiz,
    EndScreen,
    UserForm
  },
  data() {
    return {
      quizStarted: false,
      quizFinished: false,
      showForm: false,
      reward: null,
      correctAnswers: 0, // or whatever the initial value should be
      totalQuestions: 0  // or whatever the initial value should be
    }
  },

  mounted() {
    this.getRewardFromUrl();
  },

  methods: {

    getRewardFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const reward = urlParams.get('reward');
      if (reward) {
        this.reward = reward;
        this.showForm = true;
      }

      const open = urlParams.get('open');
      if (open) {
        this.showForm = true;
      }

    },

    startQuiz() {
      this.quizStarted = true;
    },
    endQuiz(results) {
      this.correctAnswers = results.corrects
      this.quizFinished = true;
    },
    showFormEvent(reward) {
      this.reward =  reward
      this.showForm = true;
    },
    resetQuiz() {
      this.quizStarted = false;
      this.quizFinished = false;
      this.showForm = false;
    },
  },
  computed: {
    componentKey() {
      if (!this.quizStarted && !this.showForm) {
        return 'startScreen';
      } else if (this.quizStarted && !this.quizFinished) {
        return 'quiz';
      } else if (this.quizFinished && !this.showForm) {
        return 'endScreen';
      } else if (this.showForm) {
        return 'form';
      }
      // Returning a default value if no condition is met
      return 'default';
    },
  }
}
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}
</style>

<style>
#app {
  display: grid;
  widows: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #241405;
  background-color: #fbfafa;
}

p {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 1.2rem;
}

#main {
  width: 70vw;
  height: 85vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: 2rem;
  align-items: center;
  justify-items: center;
}

#container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  /* padding: 2.5%; */
  background-color: #ffffff;
  box-shadow: 0px 21px 9px rgba(2, 9, 29, 0.01), 0px 12px 7px rgba(2, 9, 29, 0.03), 0px 5px 5px rgba(2, 9, 29, 0.05), 0px 1px 3px rgba(2, 9, 29, 0.06), 0px 0px 0px rgba(2, 9, 29, 0.06);
}

#container .wrapper {
  height: 100%;
}

button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #E4172C;
  width: auto;
  border: 0;
  padding: 1rem 2rem;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 600;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  border-radius: .5rem;
}

button:hover,
button:active,
button:focus {
  background: #d31d1d;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 575.98px) {
  p {
    margin-bottom: 1rem;
    line-height: 1.5;
    font-size: .9rem;
  }

  #main {
    width: 95vw;
    height: 95vh;
    gap: 1rem;
  }

  #main .powered {
    height: 35px;
  }

  button {
    padding: .5rem 1rem;
    font-size: .8rem;
    border-radius: .3rem;
  }

  #container {
    overflow: auto;
  }
}
</style>

