<template>
  <div id="quiz" v-if="currentQuestion < questions.length">
    <div class="quiz__question">
      <div class="question_wrapper">
        <div class="question_tracking">
          <p>{{ currentQuestion + 1 }} de {{ questions.length }} preguntas respondidas</p>
        </div>
        <h2>{{ questions[currentQuestion].question }}</h2>
      </div>
      <div class="question_img">
        <img :src="getImagePath(questions[currentQuestion].image)" alt="Segel Quiz" />
      </div>
    </div>

    <div class="quiz__answers">
      <header class="quiz_header">
        <p class="answer_right">Preguntas correctas: <span>{{ correctAnswers }}</span></p>
        <p class="answer_wrong">Preguntas incorrectas: <span>{{ incorrectAnswers }}</span></p>
      </header>

      <div class="quiz_options">
        <button class="options" v-for="(answer, index) in questions[currentQuestion].answers" :key="index"
                @click="submitAnswer(index)">
          {{ answer }}
        </button>
      </div>
    </div>
  </div>

  <div v-else>
    <button @click="$emit('endQuiz', { correctAnswers, incorrectAnswers })">
      Finalizar Quiz
    </button>
  </div>
</template>

<script>
import questions from '@/assets/questions.json';

export default {
  name: 'SegelQuiz',
  data() {
    return {
      currentQuestion: 0,
      correctAnswers: 0,
      incorrectAnswers: 0,
      questions: this.getRandomQuestions(questions),
    };
  },
  methods: {
    submitAnswer(index) {
      if (this.currentQuestion >= this.questions.length) return;

      if (index === this.questions[this.currentQuestion].correctAnswer) {
        this.correctAnswers++;
      } else {
        this.incorrectAnswers++;
      }

      this.currentQuestion++;

      if (this.currentQuestion === this.questions.length) {
        this.$emit('endQuiz', { corrects: this.correctAnswers });
      }
    },
    getRandomQuestions(questions) {
      for (let i = questions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        [questions[i], questions[j]] = [questions[j], questions[i]];
      }

      return questions.slice(0, 3);
    },
    getImagePath(image) {
      return require(`@/assets/${image}`);
    }
  },
  computed: {
    progress() {
      return ((this.currentQuestion + 1) / this.questions.length) * 100;
    }
  }
};
</script>

<style scoped>
.progress-bar {
  height: 5px;
  background-color: #c32f11;
}

#quiz {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: .75fr 1.25fr;
}

.quiz__question {
  position: relative;
  height: 100%;
  background-color: #2f2012;
  color: white;
  padding: 2rem;

}

.quiz__question .question_wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.quiz__question .question_img {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: soft-light;
}

.question_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.quiz_header {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #241405;
  padding: 1rem 2rem;
  color: white;
  align-content: center;
  align-items: center;
  justify-items: center;
}

.quiz_header span {
  background-color: #E4172C;
  color: white;
  border-radius: 4px;
  padding: .5rem 1rem;
  margin-left: .5rem;
}

.quiz_header p {
  margin: 0;
}

.question_tracking {
  display: block;
  text-align: right;
}

.question_tracking p {
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 2rem;
  background-color: white;
  color: #241405;
  border-radius: 2px;
  text-transform: uppercase;
  line-height: 1;
}

.quiz__question h2 {
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -3px;
}

.quiz__answers {
  padding: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  /* gap: 2rem; */
}

.quiz__answers .quiz_options {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
  padding: 2rem;
}

@media (max-width: 575.98px) {
  #quiz {
    grid-template-rows: 1fr 1fr;
  }

  .quiz__question {
    padding: 1rem;

  }

  .quiz_header {
    padding: .5rem 1rem;
  }

  .quiz_header span {
    border-radius: 2px;
    padding: .25rem .5rem;
    margin-left: .1rem;
  }

  .question_tracking p {
    font-size: .75rem;
    padding: .5rem 1rem;
    border-radius: 2px;
  }

  .quiz__question h2 {
    font-size: 1.75rem;
    letter-spacing: .25px;
  }

  .quiz__answers {
    padding: 0;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;
  }

  .quiz__answers .quiz_options {
    gap: .5rem;
    padding: 1rem;
  }

}
</style>
