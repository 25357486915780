<template>
  <div class="quiz__keyboard">
    <div class="keyboard">
      <div class="row" v-for="row in keys" :key="row">
        <button class="key" v-for="key in row" :key="key" @click="pressKey(key)">
          {{ key }}
        </button>
      </div>
      <div class="row special">
        <button class="key" @click="pressKey('@')">@</button>
        <button class="key" @click="focusNextInput()">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3081 10.3096L21.8081 14.8096L17.3081 19.3096" stroke="black" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M3.80811 5.80957C3.80811 8.19652 4.75632 10.4857 6.44414 12.1735C8.13197 13.8614 10.4212 14.8096 12.8081 14.8096H21.8081"
                stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <button class="key" @click="pressKey(' ')">Espacio</button>
        <button class="key" @click="deleteLastChar()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_501_1301)">
              <path
                  d="M5.78156 19.1362L1.5 12L5.78156 4.86375C5.84824 4.75276 5.94252 4.66093 6.05522 4.59718C6.16793 4.53344 6.29521 4.49996 6.42469 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V18.75C21 18.9489 20.921 19.1397 20.7803 19.2803C20.6397 19.421 20.4489 19.5 20.25 19.5H6.42469C6.29521 19.5 6.16793 19.4666 6.05522 19.4028C5.94252 19.3391 5.84824 19.2472 5.78156 19.1362Z"
                  stroke="#171716" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15 9.75L10.5 14.25" stroke="#171716" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
              <path d="M15 14.25L10.5 9.75" stroke="#171716" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_501_1301">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button class="key" @click="$emit('submitForm')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_499_1288)">
              <path d="M18 9V12.75H6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 9.75L6 12.75L9 15.75" stroke="black" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_499_1288">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizKeyboard',
  data() {
    return {
      keys: [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ñ'],
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', '-']
      ]
    };
  },
  methods: {
    pressKey(key) {
      this.$emit('pressKey', key);
    },
    focusNextInput() {
      this.$emit('focusNextInput');
    },
    deleteLastChar() {
      this.$emit('deleteLastChar');
    }
  }
};
</script>

<style scoped>
.quiz__keyboard {
  padding: 1rem 2rem 2rem 2rem;
  height: 100%;
}

.keyboard {
  display: grid;
  height: 100%;
  gap: .25rem;
}

.keyboard .row {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(10, 1fr);
  gap: .25rem;
  justify-content: center;
}

.keyboard .row.special {
  grid-template-columns: 1fr 1fr 6fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.keyboard .row .key {
  background-color: #dfdfdf;
  color: rgb(68, 68, 68);
  padding: 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: transform 0.1s;
}

.keyboard .row .key:active {
  transform: scale(.95);
}

@media (max-width: 575.98px) {
  .quiz__keyboard {
    visibility: hidden;
    display: none;
  }
}
</style>
